import React, { useEffect, useState } from 'react'
import { ButtonSearch } from '../../../pages/Team/Promoter/style'
import { FileSearchOutlined } from '@ant-design/icons'
import Api from '../../../services/api'
import { ViewForm } from './Aswers'
import api_v2 from '../../../services/api-v2'
import { Loading } from '../../Loading'
import { message } from 'antd'
import { CardAnswerProduct } from './CardAnswerProduct'
import { ContainerOverflow } from './styles'
import { getWorkspaceId } from '../../../utils/login'
import { contextFile } from '../../../utils/option-user'

interface IProps {
  next: (stage: number) => void
  value: (body: any) => void
  close: (value: boolean) => void
  formId: number
  body: any
  context?: string
  type?: any
}

export const Step2 = ({ next, value, formId, body, close, context, type}: IProps) => {
  const [form, setForm] = useState<any>({})
  const [dataAnswer, setDataAnswer] = useState<any>([])
  const [teste, setTeste] = useState([])
  const [error, setError] = useState<number[]>([])
  const [loading, setLoading] = useState<boolean>(false)
  const [products, setProducts] = useState<any>([])
  const [answer, setAnswer] = useState<any>([])
  const [validate, setValidate] = useState({ validate: 'no_validate', ids_answers: [] })
  const [product, setProduct] = useState<any>([])

  const checks = (id: any) => {
    let ids: any = teste
    ids.push(id)
    setTeste(ids)
  }

  const handleSetAnswers = (answers: any) => {
    let newDataAnswer = dataAnswer
    let exist = false
 
    newDataAnswer = newDataAnswer.map((answer: any) => { 
      if (answer.id === answers.id) {
        exist = true
        return { ...answer, field_value: answers.field_value }
      } else {
        return answer
      }
    })

    if (exist === false) newDataAnswer = [...newDataAnswer, answers]

    console.log(newDataAnswer, 'newDataAnswer')
    setDataAnswer(newDataAnswer)
  }

  const getForm = async () => {
    setLoading(true)
    const { data } = await Api.get(`/form/form_id/${formId}`)
    setForm(data.firebase_body)

    console.log(body)
    if (data.context === 'PRODUCT') {
      const { data } = await api_v2.get(
        `/answer/products-task?pdv_id=${body.pdv_id}${
          body.sub_workspace_id !== undefined ? `&sub_workspace_id=${body.sub_workspace_id}` : ''
        }`
      )
      setProducts(data)
      if(body.type === 'UNIQUE'){
        const produtoSelecionado = data.find(produto => produto.product_id === body.id);
        setProduct(produtoSelecionado)
        setProducts(product)
      }
    }

    setLoading(false)
  }

  const finish = (evt: any) => {
    evt.preventDefault()
  }

  const searchQuestion = (total: any) => {
    let ids: any = []
    total.forEach((field: any, index: number) => {
      let exist = false
      dataAnswer.forEach((element: any) => {
        if (element.id === field.id) {
          if (element && element.field_value && element.field_value.length > 0) exist = true
        }
      })
      if (!exist) {
        if (field.is_required) ids.push(field.id)
      } else {
        if (field.type === 'BOOLEAN') {
          let value
          dataAnswer.forEach((element: any) => {
            if (element.id === field.id) value = element.field_value[0]
          })

          if (value === 'SIM' && field.fields_true) {
            let oldId = ids
            let newId: any = searchQuestion(field.fields_true)
            ids = [...oldId, ...newId]
          }

          if (value === 'NÃO' && field.fields_false) {
            let oldId = ids
            let newId: any = searchQuestion(field.fields_false)
            ids = [...oldId, ...newId]
          }
        }
      }
    })

    return ids
  }

  const handleCheckAnswer = async () => {
    try {
      if (form.context === 'PRODUCT') {
        verifyNeed()  
      }
      if(form.context != 'PRODUCT'){
      let idsObrigatorios = searchQuestion(form.fields)
      if (idsObrigatorios.length > 0) {
        setError(idsObrigatorios)
        return
      }
    }
      let clearAnswer: any = []

      const getClearAnser = (formBody: any) => {
        
        formBody.forEach((el: any) => {
          dataAnswer.forEach((al: any) => {
            if (al.id === el.id) {
              if (el.fields_true && el.fields_true.length > 0 && el.type === 'BOOLEAN' && al.field_value[0] === 'SIM' && Array.isArray(el.fields_true)) {
               
                getClearAnser(el.fields_true)
              }
              if (
                el.fields_false &&
                el.fields_false.length > 0 &&
                el.type === 'BOOLEAN' &&
                al.field_value[0] === 'NÃO' &&
                Array.isArray(el.fields_false)
              ) {
                getClearAnser(el.fields_false)
              }
              if (al.field_type === 'UNIQUE_CHOICE') {           
                let getIndex = el.options.indexOf(al.field_value[0])
                if (el.fields && el.fields[getIndex] && el.fields[getIndex]?.subFields && Array.isArray(el.fields[getIndex]?.subFields)) getClearAnser(el.fields[getIndex]?.subFields)
              }

              if (al.field_type === 'MULTIPLE_CHOICE') {
                if (al.field_value && al.field_value.length > 0) {
                  al.field_value.forEach((ma: any) => {
                    let getIndex = el.options.indexOf(ma)
                    if (el.fields && el.fields[getIndex] && el.fields[getIndex]?.subFields && Array.isArray(el.fields[getIndex]?.subFields)) getClearAnser(el.fields[getIndex]?.subFields)
                  })
                }
              }

              clearAnswer.push(al)
            }
          })
        })
      }

      getClearAnser(form.fields)
      if(body.type === 'STORETASK'){
        setLoading(true)
        await api_v2.post('/answer/task', {
          ...body,
          answers: dataAnswer,
        })
          setTimeout(() => {
            window.location.reload()
          }, 1500);
        message.success('Resposta processada com sucesso.')
      }
     else if(body.type != 'UNIQUE'){
      await api_v2.post('/answer', {
        ...body,
        product_id: null,
        sub_workspace_id: null,
        answers: dataAnswer,
      })}
      message.success('Resposta processada com sucesso.')
      close(false)
      setLoading(true)
    } catch (error) {
      console.log(error)
      message.error('Erro ao processar resposta.')
      close(false)
      setLoading(false)
    }
  }

  useEffect(() => {
    getForm()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formId])

  // validação resposta de produtos

  const verifyAnswer = (el: any) => {
    if(body.type === 'UNIQUE'){
    if (el && el.answers && el.answers.length > 1) {
      el.answers.splice(1, 1);
    }
  }
    let exist = answer.filter((an: any) => an.product_id === el.product_id)
    if (exist.length > 0) {
      let indexFind = answer.findIndex((item: any) => item.product_id === el.product_id)

      let newAnswer = answer

      newAnswer[indexFind] = el

      setAnswer(newAnswer)
    } else {
      console.log([...answer, el], '[...answer, el]')

      setAnswer([...answer, el])
    }
  }

  const handleCheckAnswerMass = (obj: any) => {
    let convert = form

    const searchQuestion = (total: any) => {
      let ids: any = []
      total.forEach((field: any) => {
        let exist = false
        obj.forEach((element: any) => {
          if (element.id === field.id) {
            if (element && element.field_value && element.field_value.length > 0) exist = true
          }
        })

        if (!exist) {
          if (field.is_required) ids.push(field.id)
        } else {
          if (field.type === 'BOOLEAN') {
            let value
            obj.forEach((element: any) => {
              if (element.id === field.id) value = element.field_value[0]
            })

            if (value === 'SIM' && field.fields_true) {
              let oldId = ids
              let newId: any = searchQuestion(field.fields_true)
              ids = [...oldId, ...newId]
            }

            if (value === 'NÃO' && field.fields_false) {
              let oldId = ids
              let newId: any = searchQuestion(field.fields_false)
              ids = [...oldId, ...newId]
            }
          }
        }
      })

      return ids
    }

    return searchQuestion(convert.fields)
  }

  const verifyNeed = async () => {
    try {
      let noAnswer: any = []
      products.forEach((el: any) => {
        let has = answer.filter((elAnswer: any) => el.product_id === elAnswer.product_id)
        if (has.length === 0) noAnswer.push({ ids_answers: el.product_id })
      })

      setValidate({
        validate: 'validate',
        ids_answers: noAnswer,
      })

      let idsObrigatorios: any = []
      answer.forEach((el: any) => { 
        if (el.answers[0].id === 0 && el.answers[0].field_value[0] === 'sim') {
          let id: any = handleCheckAnswerMass(el.answers)
          if (id.length === 0) return
          idsObrigatorios.push({ idProduto: el.product_id, ids: id })
        }
      })

      console.log(idsObrigatorios, 'idsObrigatorios', answer)

      setError(idsObrigatorios)
      if (idsObrigatorios.length === 0 && noAnswer.length === 0) {
        setLoading(true)
        for await (let el of answer) {
          if (el.product_id && el.product_id > 0) {
            if(body.type === 'UNIQUE'){
              setLoading(true)
              let filterAnswer = el.answers.filter(item => item != null)
              await api_v2.post('/answer/task', {
                ...body,
                answers:filterAnswer ,
              })
                setTimeout(() => {
                  window.location.reload()
                }, 1500);
              message.success('Resposta processada com sucesso.')
              break
            }   

            await api_v2.post('/answer', {
              ...body,
              sub_workspace_id: null,
              answers: el.answers,
              product_id: el.product_id,
              workspace_id: getWorkspaceId(),
            })
          } else {

            await api_v2.post('/answer', {
              ...body,
              sub_workspace_id: null,
              answers: el.answer,
            })
          }
        }

        message.success('Resposta processada com sucesso.')
        close(false)
        setLoading(false)
      }
    } catch (error) {
      message.error('Erro ao processar resposta.')
      setLoading(false)
    }
  }
  return (
    <form onSubmit={finish} style={{ height: '100%' }}>
      {loading && <Loading />}
      {form.context !== 'PRODUCT' && form.fields && form.fields.length > 0 && (
        <>
          <h3
            style={{
              fontWeight: 'bold',
              fontSize: 17,
              textAlign: 'center',
            }}
          >
            {form.name}
          </h3>
          <ViewForm
            data={form}
            handleSetAnswers={handleSetAnswers}
            checkRequired={(a: any) => checks(a)}
            error={error}
            body={body}
          />
        </>
      )}

      {body.type !== 'UNIQUE' && form.context === 'PRODUCT' && form.fields && form.fields.length > 0 && (
        <>
          <h3
            style={{
              fontWeight: 'bold',
              fontSize: 17,
              textAlign: 'center',
            }}
          >
            {form.name}
          </h3>
          <ContainerOverflow>
            {products &&
              products.length > 0 &&
              products.map((item: any) => (
                <CardAnswerProduct
                  item={item}
                  answer={verifyAnswer}
                  validate={validate}
                  error={error}
                  body={body}
                  form={form}
                />
              ))}
          </ContainerOverflow>
        </>
      )}
      {body.type === 'UNIQUE' && (
        <>
          <ContainerOverflow>
            {product &&     
                  <CardAnswerProduct
                  item={product}
                  answer={verifyAnswer}
                  validate={validate}
                  error={error}
                  body={body}
                  form={form}
                  />
                
            }
          </ContainerOverflow>
        </>
      )}
      <div
        style={{
          width: '100%',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
        }}
      >
        <ButtonSearch type='button' style={{ width: 150 }} onClick={() => next(0)}>
          <FileSearchOutlined />
          Voltar
        </ButtonSearch>
        <ButtonSearch type='button' style={{ width: 150 }} onClick={() => handleCheckAnswer()}>
          <FileSearchOutlined />
          Finalizar
        </ButtonSearch>
      </div>
    </form>
  )
}
