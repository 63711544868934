import { getDataUser, getWorkspaceId } from "./login"

export const addIsScriptIsPermited = () => {
    const user = getDataUser()
    const workspace_id = getWorkspaceId()

    if(user && user.config && user.config.is_client) return false

    if (user.id === 424370 && workspace_id === 72) return true
    if (user.id === 431746 && workspace_id === 72) return true
    if (user.id === 390434 && workspace_id === 66) return true
    if (user.id === 383314 && workspace_id === 66) return true
    //if (user.id === 450386 && workspace_id === 62) return true


    if ([62, 63, 64, 65, 66, 67, 68, 69, 70, 71, 72, 73, 74, 75, 76, 77, 78, 79, 87, 93, 104, 109, 110, 111].includes(workspace_id)) {
        return [374882,450386, 370866, 383394, 374898, 374850, 383458, 383330, 374866, 11, 415826, 394290, 415058, 428930, 429282, 383522, 505122].includes(user.id)
    }
    return true
}

export const addIsVisit = () => {
    const user = getDataUser()
    const workspace_id = getWorkspaceId()

    if(user && user.config && user.config.is_client) return false

    return true
}

export const permissionDelete = () => {
    const user = getDataUser()
    if(user && user.config && user.config.is_client) return false

    return [11, 370866, 432450, 374866, 429778, 383330].includes(user.id)
}

export const editUserNotPermited = () => {
    const user = getDataUser()
    if(user && user.config && user.config.is_client) return false

    const workspace_id = getWorkspaceId()

    if ([62, 63, 64, 65, 66, 67, 68, 69, 70, 71, 72, 73, 74, 75, 76, 77, 78, 79, 87, 93, 104, 109, 110, 111].includes(workspace_id)) {
        return [
            374850,
            383458,
            455074,
            428082,
            383378,
            374882,
            433938,
            428930,
            390754,
            444818,
            390770,
            383170,
            383298,
            383474,
            383634,
            431890,
            390786,
            450386,
            415058,
            383522,
            505122
        ].includes(user.id)
    }
    return false
}
